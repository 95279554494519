import clsx from 'clsx';
import { ReactNode } from 'react';

import { Button, ButtonProps } from '@endaoment-frontend/ui/shared';

import styles from './ImageButtonRow.module.scss';

export type ImageButtonProps = { label: string; href: string; paragraph?: string; icon?: ReactNode } & Omit<
  ButtonProps<'a'>,
  'as' | 'children'
>;

export const ImageButton = ({ label, href, paragraph, icon, className, ...props }: ImageButtonProps) => {
  return (
    <Button
      as="a"
      href={href}
      target="_blank"
      rel="noreferrer"
      cardIcon={icon}
      className={clsx(className, styles['image-button'])}
      {...props}
    >
      <div>
        <h4>{label}</h4>
        {paragraph && <p>{paragraph}</p>}
      </div>
    </Button>
  );
};
