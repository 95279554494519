import clsx from 'clsx';
import { ReactElement } from 'react';

import { ImageButton, ImageButtonProps } from './ImageButton';
import styles from './ImageButtonRow.module.scss';

type AllowedChildren = ReactElement<ImageButtonProps | HTMLHRElement>;

export const ImageButtonRow = ({
  title,
  children,
  className,
}: {
  title?: string;
  children: AllowedChildren | AllowedChildren[];
  className?: string;
}) => {
  return (
    <section className={clsx(styles['image-button-row'], className)}>
      {title && <h3>{title}</h3>}
      <div>{children}</div>
    </section>
  );
};

ImageButtonRow.Button = ImageButton;
