import clsx from 'clsx';
import { useMedia } from 'react-use';
import { Autoplay, Pagination } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

import { Card } from '@endaoment-frontend/ui/shared';

import { MEDIA_SLIDES } from '../../editable';
import { SectionLabel } from '../sectionLabel';

import 'swiper/css';
import 'swiper/css/pagination';
import styles from './MediaCarousel.module.scss';
import { MediaCardProps } from './MediaCarousel.types';

const MediaCard = ({ source, title, snippet, date, href }: MediaCardProps) => {
  return (
    <a href={href} target="_blank" rel="noreferrer">
      <Card className={styles['media-card']}>
        <div className={clsx(styles['media-source'], typeof source === 'string' && styles['source-text'])}>
          {source}
        </div>
        <span className={styles['media-title']}>{title}</span>
        <p>{snippet}</p>
        <span className={styles['media-date']}>{date}</span>
      </Card>
    </a>
  );
};

export const MediaCarousel = () => {
  const isMobile = useMedia('(max-width: 55rem)', true);

  return (
    <section className={styles['media-carousel']}>
      <SectionLabel center text="Media" />
      <Swiper
        direction="horizontal"
        slidesPerView={isMobile ? 1 : 3}
        spaceBetween={25}
        loop
        modules={isMobile ? [Autoplay, Pagination] : [Autoplay]}
        pagination={isMobile}
        autoplay={{ delay: 5000, disableOnInteraction: false }}
        speed={1000}
      >
        {MEDIA_SLIDES.map((props, i) => (
          <SwiperSlide key={i + props.title}>
            <MediaCard {...props} />
          </SwiperSlide>
        ))}
      </Swiper>
    </section>
  );
};
