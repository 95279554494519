import { IconProps } from './Icon.types';

export const StarCircleIcon = ({
  width = 50,
  height = 48,
  color = 'currentColor',
  strokeColor = 'currentColor',
  strokeWidth = 1.5,
  ...props
}: IconProps) => (
  <svg width={width} height={height} viewBox="0 0 50 48" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <mask maskUnits="userSpaceOnUse" x="18.538" y="0" width="31" height="31" fill="black">
      <rect fill="white" x="18.538" width="31" height="31" />
      <path d="M34.2145 2L35.9242 10.8028C36.2352 12.4039 37.4871 13.6559 39.0882 13.9668L47.8911 15.6765L39.0882 17.3863C37.4871 17.6972 36.2352 18.9492 35.9242 20.5502L34.2145 29.3531L32.5048 20.5502C32.1938 18.9492 30.9419 17.6972 29.3408 17.3863L20.538 15.6765L29.3408 13.9668C30.9419 13.6559 32.1938 12.4039 32.5048 10.8029L34.2145 2Z" />
    </mask>
    <path
      d="M34.2145 2L35.9242 10.8028C36.2352 12.4039 37.4871 13.6559 39.0882 13.9668L47.8911 15.6765L39.0882 17.3863C37.4871 17.6972 36.2352 18.9492 35.9242 20.5502L34.2145 29.3531L32.5048 20.5502C32.1938 18.9492 30.9419 17.6972 29.3408 17.3863L20.538 15.6765L29.3408 13.9668C30.9419 13.6559 32.1938 12.4039 32.5048 10.8029L34.2145 2Z"
      fill={color}
      fillOpacity="0.25"
    />
    <path
      d="M34.2145 2L35.687 1.71401C35.5501 1.00903 34.9327 0.5 34.2145 0.5C33.4964 0.5 32.8789 1.00903 32.742 1.71401L34.2145 2ZM47.8911 15.6765L48.177 17.149C48.882 17.0121 49.3911 16.3947 49.3911 15.6765C49.3911 14.9584 48.882 14.341 48.177 14.2041L47.8911 15.6765ZM34.2145 29.3531L32.742 29.6391C32.8789 30.3441 33.4964 30.8531 34.2145 30.8531C34.9327 30.8531 35.5501 30.3441 35.687 29.6391L34.2145 29.3531ZM20.538 15.6765L20.252 14.2041C19.547 14.341 19.038 14.9584 19.038 15.6765C19.038 16.3947 19.547 17.0121 20.252 17.149L20.538 15.6765ZM32.5048 10.8029L31.0323 10.5169L32.5048 10.8029ZM29.3408 17.3863L29.0548 18.8587L29.3408 17.3863ZM39.0882 17.3863L38.8022 15.9138L39.0882 17.3863ZM39.0882 13.9668L38.8022 15.4393L39.0882 13.9668ZM32.742 2.28599L34.4517 11.0888L37.3967 10.5169L35.687 1.71401L32.742 2.28599ZM38.8022 15.4393L47.6051 17.149L48.177 14.2041L39.3742 12.4944L38.8022 15.4393ZM47.6051 14.2041L38.8022 15.9138L39.3742 18.8587L48.177 17.149L47.6051 14.2041ZM34.4517 20.2643L32.742 29.0671L35.687 29.6391L37.3967 20.8362L34.4517 20.2643ZM35.687 29.0671L33.9773 20.2643L31.0323 20.8362L32.742 29.6391L35.687 29.0671ZM29.6268 15.9138L20.824 14.2041L20.252 17.149L29.0548 18.8587L29.6268 15.9138ZM20.824 17.149L29.6268 15.4393L29.0548 12.4944L20.252 14.2041L20.824 17.149ZM33.9773 11.0888L35.687 2.28599L32.742 1.71401L31.0323 10.5169L33.9773 11.0888ZM29.6268 15.4393C31.8283 15.0117 33.5497 13.2903 33.9773 11.0888L31.0323 10.5169C30.838 11.5175 30.0555 12.3 29.0548 12.4944L29.6268 15.4393ZM33.9773 20.2643C33.5497 18.0628 31.8283 16.3413 29.6268 15.9138L29.0548 18.8587C30.0555 19.0531 30.838 19.8355 31.0323 20.8362L33.9773 20.2643ZM38.8022 15.9138C36.6007 16.3413 34.8793 18.0628 34.4517 20.2643L37.3967 20.8362C37.5911 19.8355 38.3735 19.0531 39.3742 18.8587L38.8022 15.9138ZM34.4517 11.0888C34.8793 13.2903 36.6007 15.0117 38.8022 15.4393L39.3742 12.4944C38.3735 12.3 37.5911 11.5175 37.3967 10.5169L34.4517 11.0888Z"
      fill={color}
      mask="url(#path-1-outside-1_2310_4897)"
    />
    <path
      d="M10.769 27.3993L11.8432 32.93C12.1541 34.5311 13.4061 35.7831 15.0071 36.094L20.5379 37.1682L15.0071 38.2424C13.4061 38.5534 12.1541 39.8053 11.8432 41.4064L10.769 46.9372L9.69477 41.4064C9.3838 39.8053 8.13187 38.5534 6.53078 38.2424L1 37.1682L6.53078 36.094C8.13187 35.7831 9.3838 34.5311 9.69477 32.93L10.769 27.3993Z"
      fill={color}
    />
    <path
      d="M10.769 27.3993L11.5052 27.2563C11.4367 26.9038 11.128 26.6493 10.769 26.6493C10.4099 26.6493 10.1012 26.9038 10.0327 27.2563L10.769 27.3993ZM20.5379 37.1682L20.6809 37.9045C21.0334 37.836 21.2879 37.5273 21.2879 37.1682C21.2879 36.8091 21.0334 36.5004 20.6809 36.432L20.5379 37.1682ZM10.769 46.9372L10.0327 47.0802C10.1012 47.4327 10.4099 47.6872 10.769 47.6872C11.128 47.6872 11.4367 47.4327 11.5052 47.0802L10.769 46.9372ZM1 37.1682L0.857006 36.432C0.504513 36.5004 0.25 36.8091 0.25 37.1682C0.25 37.5273 0.504513 37.836 0.857006 37.9045L1 37.1682ZM15.0071 38.2424L14.8642 37.5062L15.0071 38.2424ZM11.8432 41.4064L11.1069 41.2634L11.8432 41.4064ZM11.8432 32.93L11.1069 33.073L11.8432 32.93ZM15.0071 36.094L14.8642 36.8303L15.0071 36.094ZM10.0327 27.5423L11.1069 33.073L12.5794 32.787L11.5052 27.2563L10.0327 27.5423ZM14.8642 36.8303L20.3949 37.9045L20.6809 36.432L15.1501 35.3578L14.8642 36.8303ZM20.3949 36.432L14.8642 37.5062L15.1501 38.9787L20.6809 37.9045L20.3949 36.432ZM11.1069 41.2634L10.0327 46.7942L11.5052 47.0802L12.5794 41.5494L11.1069 41.2634ZM11.5052 46.7942L10.431 41.2634L8.95852 41.5494L10.0327 47.0802L11.5052 46.7942ZM6.67377 37.5062L1.14299 36.432L0.857006 37.9045L6.38778 38.9787L6.67377 37.5062ZM1.14299 37.9045L6.67377 36.8303L6.38778 35.3578L0.857006 36.432L1.14299 37.9045ZM10.431 33.073L11.5052 27.5423L10.0327 27.2563L8.95852 32.787L10.431 33.073ZM6.67377 36.8303C8.57507 36.461 10.0617 34.9743 10.431 33.073L8.95852 32.787C8.70586 34.0879 7.68867 35.1051 6.38778 35.3578L6.67377 36.8303ZM10.431 41.2634C10.0617 39.3621 8.57507 37.8755 6.67377 37.5062L6.38778 38.9787C7.68867 39.2313 8.70586 40.2485 8.95852 41.5494L10.431 41.2634ZM14.8642 37.5062C12.9629 37.8755 11.4762 39.3621 11.1069 41.2634L12.5794 41.5494C12.8321 40.2485 13.8493 39.2313 15.1501 38.9787L14.8642 37.5062ZM11.1069 33.073C11.4762 34.9743 12.9629 36.461 14.8642 36.8303L15.1501 35.3578C13.8493 35.1051 12.8321 34.0879 12.5794 32.787L11.1069 33.073Z"
      fill={color}
    />
    <path
      d="M41.5497 22.2277C42.3172 25.5361 42.1623 28.9918 41.1018 32.2183C40.0412 35.4449 38.1157 38.3185 35.5349 40.5264C32.9541 42.7343 29.817 44.1917 26.4652 44.74C23.1134 45.2883 19.6754 44.9064 16.5256 43.6359"
      stroke={strokeColor}
      strokeWidth={strokeWidth}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M5.33557 30.3841C4.66918 27.334 4.78295 24.1651 5.66634 21.1706C6.54972 18.1761 8.17419 15.4528 10.3894 13.2528C12.6046 11.0527 15.339 9.44702 18.3395 8.58422C21.34 7.72143 24.5096 7.62944 27.5551 8.31677"
      stroke={strokeColor}
      strokeWidth={strokeWidth}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
