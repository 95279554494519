import { ArrowIcon, NDAOTokenIcon, NVTTokenIcon } from '@endaoment-frontend/ui/icons';
import { Button } from '@endaoment-frontend/ui/shared';

import styles from './NDAOTokenCallout.module.scss';

export const NDAOTokenCallout = () => {
  return (
    <section className={styles['callout-container']}>
      <div className={styles['callout-inner']}>
        <div className={styles['ndao-callout']}>
          <div>
            <div>
              <span>
                <NDAOTokenIcon />
              </span>
              <span>
                <NVTTokenIcon />
              </span>
            </div>
            <h4>NDAO</h4>
          </div>
          <hr />
          <h5>Community Driven Impact</h5>
        </div>
        <Button as="a" className={styles['learn-more-button']} href="/ndao">
          Learn More
          <ArrowIcon width={19.5} height={11.5} />
        </Button>
      </div>
    </section>
  );
};
